import logo from './logo.svg'

export const theme = {
    color: {
      primary: "#d70005",
      primaryHover: "#c10004",
    },
    logoImage: logo,
    backend: "https://kiosk.cci-dialog.de/",
    directory: "/",
    header: {
      customer: "",
      title: "Webkiosk cci Dialog",
      bannerColor: "#d9d9d9",
      fontColor: "#000000"
    },
    info: [
      {
      text: 'Impressum',
      link: 'https://cci-dialog.de/impressumdatenschutzbestimmungen/'
    },
      {
      text: 'Datenschutz',
      link: 'https://cci-dialog.de/datenschutz/'
    },
      {
      text: 'AGB',
      link: 'https://cci-dialog.de/agb/'
    },
  ],
  login: {
    type: "internal",
    subscriptionTitle: "Noch kein Abo?",
    subscriptionText: "Schließen Sie jetzt ein Abonnement von <nobr>cci Zeitung</nobr> ab und genießen den vollen Zugriff auf die aktuelle Ausgabe, <nobr>cci Wissensportal</nobr> und das Archiv.",
    links: [{
      id: 1,
      text: 'Jetzt abonnieren',
      url: "https://cci-dialog.de/abo-mitgliedschaft/"
    }]
  }
  };